@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Bona+Nova&family=Caveat&family=Inter&family=Jost&family=Jura&family=Lora&family=Merriweather&family=Montserrat&family=Neucha&family=Noto+Sans&family=Nunito&family=Oswald&family=Philosopher&family=Playfair+Display&family=Prata&family=Roboto&family=Roboto+Condensed&family=Roboto+Slab&family=Tenor+Sans&display=swap');

@font-face {
  font-family: 'icomoon';
  src: url('./assets/fonts/icomoon/icomoon.eot?hlxldt');
  src: url('./assets/fonts/icomoon/icomoon.eot?hlxldt#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon/icomoon.ttf?hlxldt') format('truetype'),
    url('./assets/fonts/icomoon/icomoon.woff?hlxldt') format('woff'),
    url('./assets/fonts/icomoon/icomoon.svg?hlxldt#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

*,
::before,
::after {
  box-sizing: border-box;
}

* {
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
}

html {
  margin: 0;
  padding: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #ffffff;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  ::-webkit-scrollbar {
    height: 50%;
  }
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon', serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-tooltip] {
  &:hover {
    position: relative;

    &::after {
      content: attr(data-tooltip);
      display: block;
      position: absolute;
      top: 50%;
      left: calc(100% + 5px);
      transform: translateY(-50%);
      color: #ffffff;
      z-index: 10000000;

      background-color: #000000;
      height: 31px;
      border-radius: 8px;
      padding: 0 10px;
      text-align: center;
      line-height: 31px;
      font-size: 14px;
      font-weight: 600;
      transition: all 0.3s ease-in;
      white-space: nowrap;
      font-family: 'Manrope', sans-serif;
    }

    &[data-tooltip-position='left'] {
      &::after {
        left: auto;
        right: calc(100% + 5px);
      }
    }
    &[data-tooltip-position='top'] {
      &::after {
        top: auto;
        bottom: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &[data-tooltip-position='bottom'] {
      &::after {
        top: calc(100% + 5px);
        left: calc(50%);
        transform: translateX(-50%);
      }
    }
    &[data-tooltip-position='bottom-right'] {
      &::after {
        top: calc(100% + 5px);
        left: 0;
        transform: translateX(-50%);
      }
    }
  }
}

.icon-log-out:before {
  content: '\e935';
}
.icon-plus:before {
  content: '\e94f';
}
.icon-pencil:before {
  content: '\e950';
}
.icon-trash:before {
  content: '\e91b';
}
.icon-copy:before {
  content: '\e92a';
}
.icon-chevron-down:before {
  content: '\e946';
}
.icon-close:before {
  content: '\e937';
}
.icon-check:before {
  content: '\e936';
}
.icon-check--disabled:before {
  content: '\e936';
  color: #00000033;
}
.icon-download:before {
  content: '\e94c';
}
.icon-eye-on:before {
  content: '\e926';
}
.icon-eye-off:before {
  content: '\e927';
}